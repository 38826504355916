<template>
  <!-- loading -->
  <v-dialog v-model="expiration" show-overlay persistent width="400">
    <v-card color="orange" dark>
      <v-card-title class="pt-4" >
        <h5>Tu sesión ha expirado, inicia sesión.</h5>
      </v-card-title>
      <v-card-actions> <v-spacer></v-spacer>
        <v-btn color="white" text @click="logout();" active-class="white">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["expiration"],
  methods: {
    logout() {
      localStorage.empleadoIdGlobal=null;
      localStorage.nombreEmpleadoGlobal =null;
      localStorage.empresaIdGlobal = null;
      localStorage.nombrePuestoGlobal=null;
      localStorage.token = null;
      localStorage.roles = null
      this.expiration=false;
      this.$router.push("/logout");
    },
  },
};
</script>